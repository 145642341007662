<template>
  <v-container>
    <v-data-table
      :loading="loading_data_table"
      :headers="headers"
      :items="desserts"
      sort-by="corporate_name"
      :pageCount="numberOfPagesPaginate"
      :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate"
      :options.sync="options"
      class="elevation-1"
      :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions:[5,10,15],
        showFirstLastPage:true
      }"
    >


      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Etapa</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              @click:append="searchItems()"
              label="Localizar"
              @change="searchItems()"
              single-line
              hide-details
              class="mr-8"
          ></v-text-field>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" rounded dark class="mb-2 " v-bind="attrs" v-on="on"
                ><v-icon small >mdi-plus</v-icon>Nova Etapa</v-btn
              >
            </template>



            <v-card >
             <div class="title_body">
                  <span class="text_title text-h6">{{ formTitle }}</span>
                  <span class="btn_close"><v-btn icon dark @click="dialog = false" ><v-icon dark > mdi-close </v-icon></v-btn> </span>
             </div>

              <v-card-text>
                <v-container>
                  <v-form class="px-3" @submit.prevent="submit()">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          v-model="editedItem.code"
                          label="Codigo"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        ><v-text-field
                          dense
                          v-model="editedItem.name"
                          label="Nome"
                        ></v-text-field
                      ></v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="mx-0 mt-3 mr-1" @click="dialog = false"
                  >Fechar</v-btn
                >
                <v-btn class="primary mx-0 mt-3" :loading="btnLoadingSave" :disabled="btnLoadingSave" @click="save">Salvar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="550px">
            <v-card>
              <v-card-title class="text-h5"
                >Tem certeza de que deseja excluir este item ?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" @click="closeDelete">Cancelar</v-btn>
                <v-btn class="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon  class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon  class="mr-2" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>
  </v-container>
</template>


<script>
import service from "./service";
export default {
  data: () => ({
    totalStagesPaginate : 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate:0,
    options: {},
    btnLoadingSave:false,
    loading_data_table: false,
    dialog: false,
    dialogDelete: false,
    search:'',
    headers: [
      { text: "Codigo",width:0, align: "start", sortable: false, value: "code" },
      { text: "Nome", value: "name" },
      { text: "", value: "actions", sortable: false,align: "end" },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      code: "",
      name: "",
    },
    defaultItem: {
      id: "",
      code: "",
      name: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Cadastrar Etapa" : "Editar Etapa";
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
    },
    deep: true,
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loading_data_table = true;

      const { page, itemsPerPage } = this.options;

      service.getAll(itemsPerPage||10,page,this.search).then((response) => {
        this.desserts = response.data.data;
        this.totalStagesPaginate = response.data.total
        this.numberOfPagesPaginate = response.data.last_page
        this.currentPagePaginate = response.data.current_page
        this.loading_data_table = false;
      });
    },

    searchItems(){
      this.options = {}
      this.initialize()
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let editedIndex = this.editedIndex;
      service.destroy(this.editedItem).then(() => {
        this.desserts.splice(editedIndex, 1);
        this.$toast.success("Deletado com sucesso.");
      })

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;

      });
    },
    save() {
      this.btnLoadingSave = true;
      if (this.editedIndex > -1) {
        //update
        let indice = this.editedIndex;
        service.update(this.editedItem).then((response) => {
          this.btnLoadingSave = false;
          Object.assign(this.desserts[indice], response.data);
          this.close();
          this.$toast.success("Salvo com sucesso.");

        }).catch(() => {
            this.btnLoadingSave = false;
          })
      } else {
        //store
          service
          .store(this.editedItem)
          .then((response) => {
            this.btnLoadingSave = false;
            this.desserts.push(response.data);
            this.close();
            this.$toast.success("Salvo com sucesso.");

            if (this.desserts.length == 1){ this.initialize()}
          })
          .catch(() => {
            this.btnLoadingSave = false;
          })
      }

    },

  },
};
</script>
