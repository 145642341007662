import axios from '@/axios'
const route = 'register/stage' 
export default{
    getAll(ItemsPerPage,page,search){
        //console.log(ItemsPerPage)
        return  axios.get(route+'?page='+page+'&items_per_page='+ItemsPerPage+'&search='+search)
    },

    store(stage){
        return axios.post(route,stage)
    },

    update(stage){
        return axios.put(route + '/' + stage.id ,stage)
    },

    destroy(stage){
        return axios.delete(route+ '/' + stage.id)
    },

}
